<template>
    <div v-if="report" class="mt-3">
                    <b-link class="ml-3 font-weight-bold mb-md-0 mb-1 d-block" :to="{ name: 'history' }" block variant="primary"><feather-icon icon="ChevronRightIcon" class="mr-25" size="17"/>الرجوع إلى بلاغاتي</b-link>

  
    <h2 class="text-center">بلاغ رقم <span dir="ltr">#{{ reportID }}</span> - <span
            :class="'text-' + status[report.status].variant">{{ status[report.status].name }}</span></h2>
    <p class="text-center mb-2">{{ getDateFormat(report.created_at) }}</p>

        <b-row>
            <b-col md="3">
                <b-card class="text-center">
                    <h3 class="mb-2">سيتم التواصل معك قريبًا</h3>

                    <div class="d-flex justify-content-between align-items-center">
                        <h4 class="text-nowrap mr-1">رقم التواصل</h4>
                        <h4 class="text-primary text-nowrap" v-if="!editingContact">{{ report.contact }}</h4>
                    </div>
                    <div v-if="editingContact">
                        <b-form-input v-model="newContact" :placeholder="report.contact" class="mb-1 text-center"
                            dir="ltr"></b-form-input>
                        <b-button size="sm" variant='outline-secondary'
                            @click="editingContact = false; newContact = report.contact">الغاء</b-button>
                        <b-button size="sm" variant="primary" class="ml-1" @click="updateContact">حفظ</b-button>

                    </div>

                    <b-button v-if="!editingContact" variant="flat-secondary"
                        @click="editingContact = true">تعديل</b-button>

                </b-card>
            </b-col>
            <b-col>
                <b-card title="صور الحادث">
                    <b-row>
                        <b-col class="text-center mb-2 mb-md-0" v-for="(img, i) in report.files.split(',')" :key="i">
                            <img width="200px" :src="'https://api.njm.solutions/images/' + img" />

                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
            <b-col md="4">
                <b-card :title="'الأطراف (' + report.cars.length + ')'">
                    <div v-for="(car, i) in report.cars" :key="i">
                        <div class="d-flex justify-content-between px-4 align-items-center">
                            <div>
                                <h3>
                                    {{ car.letters.split("").map(l => allLetters[l.toUpperCase()]).reverse().join(" ") }}
                                </h3>
                                
                                <h3>
                                    {{ car.letters.split("").join(" ").toUpperCase() }}
                                </h3>
                            </div>
                            <h4 class="text-center text-secondary">{{ car.type == 0 ? 'خصوصي' : 'نقل' }}</h4>
                            <div dir="ltr">
                                <h3>{{ car.numbers.split("").map(l => allNumbers[l]).reverse().join(" ") }}</h3>
                                <h3>{{ car.numbers.split("").join(" ") }}</h3>
                            </div>
                        </div>

                        <hr v-if="i !== report.cars.length - 1" />
                    </div>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>


<script>
import { BCard, BRow, BCol, BButton, BFormInput, BLink } from 'bootstrap-vue'

import { getAllLetters, getAllNumbers } from '@core/utils/translator.js'
import { getDateFormat } from '@core/utils/format';
export default {
    components: {
        BCard,
        BLink,
        BRow,
        BCol,
        BButton,
        BFormInput
    },
    computed: {
        reportID() {
            return this.$router.currentRoute.params.id;
        },
        allLetters() {
            const allLetters = getAllLetters();
            return allLetters;
        },
        allNumbers() {
            return getAllNumbers();
        },
    },
    created() {
        fetch('https://api.njm.solutions/report/' + this.reportID, {
            credentials: 'include',
            method: 'GET',
        })
            .then(res => res.json()).then(res => {
                if (res.status == 200) {
                    res.data.cars = JSON.parse(res.data.cars);
                    this.report = res.data;
                    this.newContact = this.report.contact;
                    console.log(this.report)
                } else {
                    alert('خطأ في إحضار معلومات البلاغ')
                }
            })
    },
    data() {
        return {
            editingContact: false,
            newContact: '',
            status: {
                1: { variant: 'warning', name: 'تحت المراجعة' },
                2: { variant: 'success', name: 'تمت المعالجة' },
                0: { variant: 'secondary', name: 'تم التنازل' },
            },
            report: null,
            getDateFormat
        }
    },
    methods: {
        showSwal(type, title) {
            this.$swal({
                icon: type,
                title,
                showConfirmButton: false,
                timer: 2000,
                buttonsStyling: false,
            })
        },
        updateContact() {
            if (this.newContact == this.report.contact) return this.editingContact = false;
            fetch('https://api.njm.solutions/report/' + this.reportID + '/edit', {
                credentials: 'include',
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ contact: this.newContact }),
            })
                .then(res => res.json()).then(res => {
                    if (res.status === 200) {
                        this.report.contact = this.newContact;
                        this.editingContact = false;
                        this.showSwal('success', 'تم تغيير رقم التواصل بنجاح')
                    } else {
                        if (res.status === 400) return this.showSwal('error', 'يرجى كتابة رقم جوال صحيح، يبدأ ب 05'); else this.showSwal('error', 'حدث خطأ اثناء تحديث رقم التواصل')
                    }
                })
        }
    }
}
</script>