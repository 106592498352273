const isDate = function (date) {
  return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
}

export const getDateFormat = (date) => {
  if (!isDate(date)) return 'حدث خطأ';
  let options = {
    day: 'numeric', month: 'long', weekday: 'long', year: 'numeric', hour: 'numeric',
    minute: 'numeric',
  },
    locales = "ar-u-nu-latn";
  return new Intl.DateTimeFormat(locales, options).format(new Date(date))
}