const EnglishToArabicLetters = {
    'A': 'ا',
    'B': "ب",
    "J": "ح",
    "D": "د",
    "R": "ر",
    "S": "س",
    "X": "ص",
    "T": "ط",
    "E": "ع",
    "G": "ق",
    "K": "ك",
    "L": "ل",
    "Z": "م",
    "N": "ن",
    "H": "ه",
    "U": "و",
    "V": "ى"
}

const EnglishToArabicNumbers = {
    '0': "٠",
    "1": "١",
    "2": "٢",
    "3": "٣",
    "4": "٤",
    '5': "٥",
    '6': "٦",
    '7': "٧",
    '8': "٨",
    '9': "٩"
}

exports.getAllLetters = () => {
    return EnglishToArabicLetters;
}
exports.getAllNumbers = () => {
    return EnglishToArabicNumbers;
}

exports.getAllCities = () => {
    return [
        { id: 0, name: 'الرياض' },
        { id: 1, name: 'مكة المكرمة' },
        { id: 2, name: 'الشرقية' },
        { id: 3, name: 'القصيم' },
        { id: 4, name: 'عسير' },
        { id: 5, name: 'المدينة المنورة' },
        { id: 6, name: 'حائل' },
        { id: 7, name: 'تبوك' },
        { id: 8, name: 'جازان' },
        { id: 9, name: 'الباحة' },
        { id: 10, name: 'الحدود الشمالية' },
        { id: 11, name: 'الجوف' },
    ];
}

exports.translateToArabicLetters = (letters) => {
    return letters.filter(l=>l).map(l => EnglishToArabicLetters[l.toUpperCase()]).reverse();
};
exports.translateToArabicNumbers = (numbers) => {
    return numbers.map(n => EnglishToArabicNumbers[n]);
}

